@media only screen and (max-width: 1000px) {
  .desktop-slider {
    display: none;
  }
}
@media only screen and (min-width: 1000px) {
  .mobile-slider {
    display: none;
  }

  .log-out {
    position: absolute;
    top: -70px;
  }

  .log-out .nav-link {
    display: inline-block;
  }

  #date-picker .dropdown {
    float: right;
  }
}

@media only screen and (max-width: 1000px) {
  img.app-store-img {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .video-caption {
    top: 5%;
  }
  .video-sub-header {
    font-size: 4rem;
  }
  .video-header {
    font-size: 4.5rem;
  }

  #video-background iframe {
    transform: translateY(-16%);
  }
}

@media only screen and (max-width: 768px) {
  nav img.app-store-img {
    display: none;
  }

  nav.navbar.navbar-expand-lg.navbar-light.bg-light {
    padding-top: 30px;
  }

  .video-caption {
    display: none;
  }

  #video-background {
    height: 300px;
  }

  #video-background iframe {
    height: 300px;
    transform: translateY(0);
  }

  .selected-date {
    margin: 20px auto;
  }

  .full-day,
  .half-day {
    width: 100%;
  }

  .concierge img {
    max-width: 100%;
    margin-bottom: 30px;
  }

  #concierge h1,
  #lounges h1,
  #select-hotspot h2,
  #how-it-works h1 {
    font-size: 2rem;
  }

  #lounges p {
    padding: 0;
    width: 100%;
  }

  #login {
    padding: 15px;
  }

  .login-box {
    padding: 30px;
  }

  #login h1 {
    font-size: 2rem;
    text-align: center;
  }

  #sms-verification {
    padding: 15px;
  }

  .sms-verification-box {
    padding: 15px;
  }

  .verification-actions-box {
    max-width: 100%;
  }

  #sms-verification .sms-input input[type="number"] {
    margin-right: 10px;
    width: 55px !important;
  }

  #sms-verification .verify-button {
    margin: auto;
    display: block;
    width: 200px;
  }

  .resend,
  .back {
    text-align: center;
  }

  .upload-file {
    position: inherit;
    margin-top: 5px;
    float: right;
  }

  img.upload-icon {
    margin: auto;
    display: block;
  }

  .app-with-hand {
    margin-top: 30px;
  }

  .guest-numbers {
    padding: 0 15px;
  }

  section#guest-picker .row {
    margin-right: 0px;
    margin-left: 0px;
  }

  #reservation-detail .bold-text {
    font-size: 2.2rem;
  }

  #reservation-detail .light-text {
    font-size: 1.6rem;
  }
  #all-boats .col-md-6.col-sm-12 {
    border: none !important;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid grey !important;
  }

  .boat-item .red-text {
    margin-bottom: 20px;
  }

  .hotspot-item-long {
    height: 200px;
  }

  #download-app {
    background: none;
    text-align: center;
  }

  .concierge {
    text-align: center;
  }

  .selected-day {
    width: 100%;
  }

  .selected-boat-image-gallery img {
    margin-top: 10px;
  }

  .row.select-location .col-md-6.col-sm-12 {
    margin-top: 50px;
  }

  #accept-pay .location-preview {
    margin-top: 30px;
  }

  .drop-off-preview {
    margin-top: 100px !important;
  }

  .fee-item {
    font-size: 17px;
  }

  .concierge-info p {
    padding: 0;
  }

  #guest-picker h2 {
    font-size: 25px;
    text-align: center;
  }

  #selected-boat-detail .bold-text {
    font-size: 1.6rem;
  }

  #selected-boat-detail .light-text {
    font-size: 1.6rem;
  }

  #selected-boat-detail p.item-info {
    font-size: 20px;
  }

  h1.choosed-boat {
    font-size: 1.6rem;
  }

  .day-price p {
    font-size: 24px;
  }

  .location-preview p {
    font-size: 18px;
    text-align: center;
  }

  #accept-pay .col-md-6.col-sm-12.selected-boat-image-gallery > img {
    display: none;
  }

  .selected-boat-image-gallery {
    margin-top: 0;
  }

  #accept-pay {
    margin: 0;
  }

  #logo {
    border-bottom: none;
  }

  button.navbar-toggler {
    margin-top: -20px;
    background: white;
    margin-left: -20px;
  }

  span.guest-numbers {
    margin-top: 20px;
  }

  button#hotspot-dropdown {
    font-size: 35px;
    margin: auto;
    display: block;
  }

  .dropdown-menu.show {
    right: 0;
    width: min-content;
    left: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    top: 64px !important;
    box-shadow: 0 10px 35px -10px;
  }
}
