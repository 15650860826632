@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,700,900&display=swap&subset=latin-ext");

:root {
  --card-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  --card-hover-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  position: relative;
}

img {
  max-width: 100%;
}

a {
  color: #eb262f;
}

a:hover {
  color: #eb262f;
}

input:focus,
button:focus {
  outline: none;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px !important;
  }
}

main.not-home {
  margin-top: 140px;
}

section#login,
section#register,
section#sms-verification {
  margin-top: -30px;
}

/* Header */

.header {
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid black;
}

.header.at-home {
  background-color: transparent;
  border-bottom: none;
}

.header.at-home.scrolled {
  background-color: white;
  border-bottom: 1px solid black;
}

.ct-navbar {
  margin-left: 4rem;
  flex: 1;
  display: none;
}

.ct-navbar__navigation {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 0;
  list-style: none;
  justify-content: space-between;
}

.ct-navbar__navigation-section {
  display: flex;
}

.ct-navbar__navigation-item {
  position: relative;
}

.ct-navbar__navigation-link {
  display: block;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  transition: color 0.2s ease;
  position: relative;
  cursor: pointer;
}

.ct-navbar__navigation-link:hover {
  color: rgb(224, 19, 19);
}

.ct-navbar__navigation-expanded-area {
  padding: 1rem;
  background-color: rgba(0, 0, 0, 1);
  z-index: 999;
  position: absolute;
  top: 100%;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.2s ease, opacity 0.2s ease 0.2s,
    pointer-events 0.2s ease 0.2s, visibility 0.2s ease 0.2s;
}

.ct-navbar__navigation-expanded-area:hover,
.ct-navbar__navigation-link:hover + .ct-navbar__navigation-expanded-area {
  pointer-events: all;
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}

.ct-navbar__navigation-expanded-area-list {
  list-style: none;
}

.ct-navbar__navigation-expanded-area-item {
  position: relative;
}

.ct-navbar__navigation-expanded-area-link {
  display: block;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  transition: color 0.2s ease;
  position: relative;
  cursor: pointer;
}

.ct-navbar__navigation-expanded-area-link:hover {
  color: rgb(224, 19, 19);
  text-decoration: none;
}

/* .ct-navbar__navigation-link.last::after {
  content: "";
  position: absolute;
  right: 0;
  top: 20%;
  height: 50%;
  width: 3px;
  background-color: black;
} */

.ct-navbar__navigation-link:hover {
  text-decoration: none;
}

.ct-navbar__app-store-link {
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.ct-mobile-navbar__toggle {
  padding: 8px 12px;
  border-radius: 8px;
}

.ct-mobile-navbar__close {
  text-align: right;
}

.ct-mobile-navbar__navigation-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 100vh;
  transition: transform 0.5s cubic-bezier(1, 0.01, 0.9, 1.17);
  background: white !important;
  z-index: 99999;
  padding: 1rem 2rem;
}

.ct-mobile-navbar__navigation-wrapper.hidden {
  transform: translateX(100%);
}

.ct-mobile-navbar__navigation-wrapper.show {
  transform: translateX(0%);
}

.ct-mobile-navbar__navigation {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  list-style: none;
}

.ct-mobile-navbar__navigation-item {
  position: relative;
}

@media only screen and (min-width: 800px) {
  .ct-navbar {
    display: flex;
  }

  .ct-mobile-navbar__toggle {
    display: none;
  }

  .ct-mobile-navbar__navigation-wrapper {
    display: none;
  }

  .ct-navbar__app-store-link {
    align-items: center;
    justify-content: center;
  }

  .ct-navbar__navigation-expanded-area {
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.7);
    width: 150%;
    position: absolute;
    top: 100%;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform-origin: top;
    transform: scaleY(0);
    transition: transform 0.2s ease, opacity 0.2s ease 0.2s,
      pointer-events 0.2s ease 0.2s, visibility 0.2s ease 0.2s;
  }
}

/* ------- */

/* Landing */

.landing {
  height: 100vh;
  background-image: url("assets/slider/saxdor_go_main.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .landing__booking-options-section > * {
  flex: 1;
} */

.landing__booking-options-section {
  margin: auto 3rem 1rem 3rem;
  border-radius: 999px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: relative;
}

.landing__booking-description {
  color: white;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: bold;
}

.landing__booking-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing__booking-option-text {
  margin: 0 0 0 1rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
}

.landing__booking-option-link {
  display: flex;
  align-items: center;
  color: white;
}

.landing__booking-option-link:hover {
  text-decoration: none;
  color: white;
}
.landing__booking-option-link img {
  width: 57px;
  height: 57px;
}

@media only screen and (max-width: 800px) {
  .landing__booking-options-section {
    display: none;
  }

  .landing-sub-header {
    font-size: 2rem !important;
  }

  .landing-header {
    font-size: 4rem !important;
    line-height: 0.8;
  }

  .landing-text {
    font-size: 1rem !important;
    font-weight: bold !important;
  }
}

@media only screen and (max-width: 800px) {
  .landing-header {
    font-size: 3rem !important;
    line-height: 0.8;
  }

  .landing {
    height: 70vh;
  }
}

/* ------- */

a.navbar-brand {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20%;
  text-align: center;
  margin-right: 0;
}

a.nav-link {
  color: black !important;
  position: relative;
  z-index: 999999999999999999;
}

.bg-light {
  background-color: #fbfdff !important;
}

/* @media (min-width: 1300px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 3rem !important;
  }
} */

nav.navbar.navbar-expand-xl.navbar-light.bg-light {
  background: #ffffff !important;
  transform: translateY(17px);
}

nav.navbar.navbar-expand-xl.navbar-light.bg-light {
  padding-left: 0px;
  padding-right: 1px;
}

@media (min-width: 992px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.5rem;
  }
}

.logo-header {
  position: relative !important;
  left: 0 !important;
  width: 100% !important;
  margin: 0;
  text-align: center;
  float: left;
  margin-top: 30px;
}

#logo {
  height: 20px !important;
  border-bottom: 1px solid #333;
  line-height: 45px;
  padding: 15px 0;
  display: block;
}

.logo-img {
  max-width: 95px;
  max-height: 75px !important;
  vertical-align: middle;
  height: auto;
  position: relative;
  z-index: 99;
  margin-top: -15px;
}

.logo__image {
  width: 300px;
}

/* #vd-loader {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  height: 100vh;
  z-index: 999;
  top : 0;
  background: rgba(255, 255, 255, 0.507);
} */

.vd-loader-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(255, 255, 255, 0.301);
  z-index: 999;
}

.vd-loader-item svg {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  width: 100%;
}

.app-store-img {
  max-width: 100%;
  width: 120px;
  text-align: center;
  margin: auto;
  margin-bottom: -80px;
  margin-top: 0;
  display: block;
}

#video-background {
  height: 750px;
  overflow: hidden;
}

#video-background iframe {
  height: 750px;
}

#how-it-works {
  padding-top: 30px;
  border-bottom: 0.5px solid #333;
}

#how-it-works h1 {
  font-weight: bolder;
  font-size: 3rem;
  letter-spacing: 1.8px;
  text-align: center;
}

#how-it-works p {
  margin: auto;
  display: block;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

#carousel {
  border-bottom: 0.5px solid #333;
  padding-bottom: 1px;
}

#select-hotspot {
  padding: 5rem 0;
  border-bottom: 0.5px solid #333;
  background-color: #f5f5f6;
}

#select-hotspot h2 {
  font-weight: bolder;
  font-size: 3rem;
  letter-spacing: 1.8px;
  text-align: center;
}

#select-hotspot p {
  margin: auto;
  display: block;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

#download-app {
  background: url("assets/images/download-app.png");
  background-size: 38%;
  background-position: 60% !important;
  background-repeat: no-repeat;
  height: 550px;
  padding: 100px 0 !important;
  background-color: #f5f5f6;
}

#download-app .img {
  max-width: 100%;
  width: 200px;
}

#download-app span {
  color: rgb(224, 19, 19);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 1px;
}

#download-app h1 {
  margin-top: 25px;
  font-weight: bolder;
}

.lounges-photo {
  background: url("assets/images/lounges.png");
  height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
}

#lounges {
  margin: 50px 0;
}

#lounges h1 {
  font-weight: bolder;
  font-size: 3rem;
  letter-spacing: 1.8px;
}

#lounges p {
  width: 60%;
  margin: auto;
  display: block;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  background-image: none;
}

.carousel-control-next-icon:after {
  content: ">";
  font-size: 75px;
  color: #000;
}

.carousel-control-prev-icon:after {
  content: "<";
  font-size: 75px;
  color: #000;
}

.carousel-indicators li {
  height: 20px;
  width: 20px;
  background-color: grey;
  border-radius: 100%;
}

.carousel-indicators li.active {
  background-color: red;
}

.carousel-text-left {
  position: absolute;
  left: 14%;
  top: 6%;
  z-index: 999;
  max-width: 850px;
}

.carousel-text-right {
  position: absolute;
  max-width: 800px;
  z-index: 999;
  right: 12%;
  top: 20%;
}

.red-text {
  color: red;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
}

.carousel-control-next,
.carousel-control-prev {
  z-index: 99 !important;
}

.carousel-app-store {
  width: 150px;
  margin-top: 15px;
}

.carousel-text-left h1,
.carousel-text-right h1 {
  font-size: 3rem;
  font-weight: bolder;
  color: black;
}

.carousel-text-left p,
.carousel-text-right p {
  width: 54%;
  font-size: 20px;
  font-weight: 400;
}

.carousel-text-right > * {
  display: block;
  text-align: right;
  margin-right: 0;
  margin-left: auto;
}

.concierge {
  padding: 30px 0;
  background: #f5f5f6;
}

#concierge h1 {
  font-size: 3rem;
  text-align: center;
  letter-spacing: 1.8px;
  font-weight: bolder;
}

#concierge .concierge-p {
  margin: auto;
  display: block;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.concierge .concierge-info {
  padding-top: 50px;
}

.concierge-info h2 {
  font-size: 2.3rem;
  font-weight: bold;
}

.concierge-info p {
  font-size: 18px;
  padding-right: 15%;
}

.img {
  max-width: 100%;
}

.concierge .img {
  width: 80%;
  margin: auto;
  display: block;
}

#footer {
  padding: 40px 0 50px 0;
  background: #e6e7e8;
}

#footer ul li {
  list-style: none;
  padding: 0;
}

#footer ul {
  padding: 0;
}

#footer h2 {
  font-size: 1.4rem;
  font-weight: bold;
}

.footer__header {
  text-transform: uppercase;
}

.footer__booking-option {
  margin: 1.5rem 0;
}

.footer__booking-option-link {
  display: flex;
  align-items: center;
}

.footer__booking-option-link:hover {
  text-decoration: none;
}

.footer__booking-option-text {
  margin: 0 0 0 1rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  color: black;
}

.footer__booking-option-link img {
  width: 60px;
  height: 60px;
}

.footer__locations {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.footer__location-item {
  margin: 0.4rem 0;
}

.footer__location-link {
  display: block;
  font-size: 1.1rem;
  color: black;
  transition: color 0.2s ease;
}

.footer__location-link:hover {
  color: #eb262f !important;
  text-decoration: none;
}

.footer__social-media {
  margin-top: 2.5rem !important;
}

.footer__social-media-items {
  display: flex;
  flex-direction: column;
}

.footer__social-media-item {
  margin: 1rem 0;
  display: flex;
}

.footer__social-media-link {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.footer__social-media-link:hover {
  text-decoration: none;
}

.footer__social-media-text {
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  margin-left: 0.5rem;
}

.footer__newsletter-wrapper {
  display: flex;
  align-items: flex-start;
}

.footer__newsletter-wrapper img {
  margin-right: 1rem;
  width: 100px;
}

.footer__newsletter-input-container {
  display: flex;
}

.footer__newsletter-input-label {
  border: 2px solid #323232;
  border-radius: 0;
  -moz-transform: skewX(-23deg);
  -webkit-transform: skewX(-23deg);
  -o-transform: skewX(-23deg);
  -ms-transform: skewX(-23deg);
  transform: skewX(-23deg);
  padding: 4px 8px;
  flex: 1;
  background-color: white;
}

.footer__newsletter-input {
  padding: 8px;
  flex: 1;
  border: 0;
  box-shadow: none;
  -moz-transform: skewX(23deg);
  -webkit-transform: skewX(23deg);
  -o-transform: skewX(23deg);
  -ms-transform: skewX(23deg);
  transform: skewX(23deg);
  background-color: transparent;
}

.footer__newsletter-submit-button > button {
  background-color: #eb262f;
  cursor: pointer;
  font-family: inherit;
  border: none;
  color: white;
  padding: 4px 12px;
  height: 52px;
  -moz-transform: skewX(-23deg);
  -webkit-transform: skewX(-23deg);
  -o-transform: skewX(-23deg);
  -ms-transform: skewX(-23deg);
  transform: skewX(-23deg);
}

.footer__newsletter-submit-button-text {
  -moz-transform: skewX(23deg);
  -webkit-transform: skewX(23deg);
  -o-transform: skewX(23deg);
  -ms-transform: skewX(23deg);
  transform: skewX(23deg);
}

.footer__download-app {
  margin-top: 2.5rem !important;
}

.footer__download-app-description {
  margin-bottom: 1rem;
}

.copyright {
  margin-top: 20px;
  font-size: 12px;
}

.copyright a {
  text-decoration: underline;
}

.video-background {
  position: relative;
  background: #000;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -99;
}

.video-background::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.video-foreground,
.video-background iframe {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    width: 300%;
    left: -100%;
  }
}

#video-background iframe {
  height: 1080px;
  transform: translateY(-20%);
}

.landing-description {
  position: absolute;
  z-index: 10;
  top: 20%;
  left: 0;
  right: 0;
}

.landing-description > * {
  display: block;
}

.landing-sub-header {
  font-size: 5rem;
  color: white;
  font-weight: 300;
  text-align: center;
}

.landing-header {
  font-size: 6rem;
  color: white;
  font-weight: bolder;
  text-align: center;
}

.landing-text {
  font-size: 2rem;
  color: white;
  font-weight: normal;
  text-align: center;
  width: 50%;
  margin: auto;
  margin-top: 50px;
}

.SingleDatePicker_picker {
  position: inherit !important;
  margin-top: 20px;
  box-shadow: 0 10px 35px -10px rgba(197, 197, 197, 0.603);
}

.SingleDatePicker {
  padding: 10px;
  margin: 20px 0;
}

.DateInput {
  margin: auto !important;
  display: block !important;
}

.selected-date {
  padding: 10px 50px;
  margin: auto;
  border: 2px solid grey;
  color: rgb(81, 81, 81);
  border-radius: 20px;
  display: block;
  width: fit-content;
  margin-bottom: 50px;
  font-weight: bold;
}

.selected-day {
  font-weight: bold;
  color: white;
  background: rgb(0, 0, 0);
  padding: 10px 50px;
  margin: auto;
  display: block;
  width: 50%;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.selected-day.active {
  background: rgb(207, 0, 0);
}

.selected-day.disabled {
  background: rgb(209, 209, 209);
}

.hotspots {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 300px;
  grid-gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .hotspots {
    grid-template-columns: 1fr;
    margin: 0 1rem;
  }
  .hotspot-item-second {
    grid-column-start: 1 !important;
  }
}

.hotspots-header {
  text-transform: uppercase;
}

.hotspot-item {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  grid-column-start: 1;
  border-radius: 10px;
  box-shadow: var(--card-shadow);
  transition: box-shadow 0.2s ease;
  position: relative;
}

.hotspot-item:hover {
  box-shadow: var(--card-hover-shadow);
}

.hotspot-item:hover img {
  transform: scale(1.1);
}

.hotspot-item-second {
  grid-column-start: 2;
}

.hotspot-item img {
  width: 100% !important;
  height: 100% !important;
  transition: transform 0.2s ease;
}

.hotspot-item-long {
  height: 400px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 50% !important;
  position: relative;
  cursor: pointer;
}

.hotspot-img-filter {
  position: absolute;
  z-index: 10;
  background: #00000030;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hotspot-name {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  z-index: 11;
  font-weight: bold;
  font-size: 24px;
}

.hotspot-available-options {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: white;
  z-index: 11;
}

.skew-submit-box {
  transform: skew(-23deg);
  width: 94px;
  height: 32px;
  background: rgb(0, 0, 0);
  position: absolute;
  top: 0;
  z-index: 0;
  border-radius: 4px;
}

.custom-submit-button {
  position: relative;
  display: inline-block;
  transform: translateX(-10px);
}

.custom-submit-button span {
  color: white;
  position: relative;
  z-index: 9;
  padding-left: 25px;
  line-height: 32.5px;
  font-size: 12px;
}

.scrollarea {
  height: 250px;
}

.scrollarea-content.content a {
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid;
  font-size: 17px;
  font-weight: bold;
  color: rgb(97, 97, 97);
  transition: 0.3s all ease;
}

.scrollarea-content.content a:hover {
  font-size: 18.5px;
  color: black;
}

.selected-time {
  color: black !important;
  font-size: 21px !important;
}

#login {
  background: url("./assets/slider/saxdor_go_main.jpg");
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
}

.login-box {
  background: rgba(255, 255, 255, 0.822);
  padding: 90px 80px;
  margin: auto;
  display: block;
}

#login input.react-phone-number-input__input.react-phone-number-input__phone {
  background: transparent;
  border-bottom: 1px solid;
  padding: 5px;
}

.join-button {
  max-width: 100%;
  width: 350px;
  margin: auto;
  display: block;
  margin-top: 40px;
}

#login .grey-text {
  color: grey;
  margin-top: 15px;
  padding-left: 45px;
  font-size: 14px;
}

#login .black-text {
  color: #000;
  padding-top: 15px;
  margin: auto;
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.react-phone-number-input__country-select-arrow {
  font-size: 31px;
  color: rgb(97, 97, 97) !important;
}

.react-phone-number-input__country.react-phone-number-input__country--native {
  font-size: 18px;
}

#sms-verification {
  display: flex;
  align-items: center;
  height: 100vh;
  background: url("./assets/slider/saxdor_go_main.jpg");
  background-size: contain;
}

#sms-verification h1 {
  font-weight: bolder;
  text-align: center;
}

#sms-verification .sms-verification-text {
  text-align: center;
  color: black;
  margin-bottom: 40px;
}

.sms-verification-box {
  margin: auto;
  display: block;
  background: rgba(255, 255, 255, 0.822);
  padding: 90px 80px;
}

#sms-verification .sms-input input[type="number"] {
  margin-right: 20px;
  border: 1px solid black !important;
  border-radius: 8px !important;
  font-size: 28px;
  font-weight: bold;
  color: rgb(22, 19, 19);
  font-family: "Montserrat", sans-serif;
}

.styles_react-code-input-container__tpiKG.sms-input {
  width: 100% !important;
}

.styles_react-code-input__CRulA {
  text-align: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#sms-verification .verify-button {
  max-width: 100%;
  width: 150px;
  cursor: pointer;
}

.verification-actions-box {
  width: 370px;
  margin: auto;
}

.resend,
.back {
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
}

.resend svg.svg-inline--fa.fa-sync.fa-w-16,
.back svg.svg-inline--fa.fa-long-arrow-alt-left.fa-w-14 {
  margin-right: 5px;
}

.create-profile-box {
  margin: auto;
  display: block;
  margin-top: 30px;
}

.create-profile-box input {
  border: none;
  border-bottom: 1px solid black;
  padding-bottom: 3px;
  margin-bottom: 20px;
  width: 100%;
}

.save-profile-button {
  max-width: 100%;
  width: 300px;
  margin: auto;
  display: block;
  margin-top: 30px;
  margin-bottom: 50px;
}

.upload-file {
  border: none !important;
  position: absolute;
  bottom: 0;
}

img.upload-icon {
  margin-bottom: 30px;
}

.download-app-img {
  max-width: 100%;
  width: 150px;
  margin-top: 20px;
}

.app-with-hand {
  max-width: 130%;
  transform: translateX(-20%);
  max-width: 100%;
}

.download-app-box {
  padding: 40px 50px 0 50px;
}

#download .red-text {
  font-size: 15px;
  letter-spacing: inherit;
  padding-bottom: 20px;
}

#download h1 {
  font-weight: bolder;
  margin-top: 30px;
  margin-bottom: 15px;
}

#download .bold-text {
  font-weight: bold;
}

#my-charters {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-charters-box {
  margin: auto;
  display: block;
}

.my-charters-box h1 {
  text-align: left;
  font-weight: bolder;
  margin-bottom: 40px;
}

#my-charters .red-text {
  font-size: 18px;
}

#my-charters h2 {
  font-weight: bolder;
  font-size: 26px;
}

#my-charters hr {
  border-top: 1px solid rgba(0, 0, 0, 0.54);
}

#my-charters .grey-text {
  color: grey;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #eb262f !important;
  border: #eb262f !important;
}

button.DayPickerKeyboardShortcuts_buttonReset.DayPickerKeyboardShortcuts_buttonReset_1.DayPickerKeyboardShortcuts_show.DayPickerKeyboardShortcuts_show_2.DayPickerKeyboardShortcuts_show__bottomRight.DayPickerKeyboardShortcuts_show__bottomRight_3 {
  display: none;
}

.DateInput_input__focused {
  border-color: #eb262f !important;
}

.guest-numbers {
  font-weight: bolder;
  font-size: 24px;
  cursor: pointer;
  transition-duration: 0.2s;
  padding: 0 20px;
  color: grey;
}

.guest-numbers:hover {
  font-size: 28px;
}

.guest-selected {
  color: red;
  padding: 15px;
  border: 2px solid red;
  border-radius: 100%;
  font-size: 28px !important;
  margin: 0 13px;
}

section#guest-picker .row {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin-bottom: 30px;
}

.next-button {
  max-width: 100%;
  width: 250px;
  margin-bottom: 50px;
}

.header-booknow {
  max-width: 100%;
  width: 150px;
}

.grey-border-button {
  width: 210px;
  text-align: center;
  border: 1.5px solid rgb(75, 75, 75);
  border-radius: 15px;
  color: grey;
  padding: 5px 0;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  margin: 15px 10px;
}

#reservation-detail .light-text {
  font-size: 2.4rem;
  font-weight: 400;
  margin: 0;
  margin-top: 20px;
}

#reservation-detail .bold-text {
  font-size: 3rem;
  font-weight: bolder;
  letter-spacing: 4px;
  margin-bottom: 40px;
}

#reservation-detail hr {
  border-top: 1px solid rgba(0, 0, 0, 0.692);
}

.boat-item {
  position: relative;
  margin: 25px 0;
}

.price {
  position: absolute;
  right: 0;
  top: 0;
}

.boat-item span {
  display: block;
  width: max-content;
}

.boat-item .red-text {
  color: red;
  font-size: 17px;
  letter-spacing: inherit;
  margin-bottom: 10px;
}

.boat-item p.bold-text {
  font-weight: bolder;
  font-size: 24px;
  margin: 0;
}

.boat-item p.item-info {
  margin: 0;
  font-size: 15px;
}

span.time-info {
  font-weight: bold;
  color: grey;
}

.boat-item p.item-info span {
  display: inline-block;
  font-weight: bold;
  padding-right: 5px;
}

.boat-item .carousel.slide {
  margin-top: 20px;
}

.boat-item .carousel-inner {
  border-radius: 30px;
}

.boat-item span.amount {
  font-weight: bolder;
  font-size: 20px;
  letter-spacing: 1px;
}

#all-boats .row {
  margin-top: 40px;
}

#all-boats .col-md-6.col-sm-12:nth-child(even) {
  border-bottom: 1px solid;
}

#all-boats .col-md-6.col-sm-12:nth-child(odd) {
  border-right: 1px solid !important;
  border-bottom: 1px solid;
}

#all-boats .col-md-6.col-sm-12:last-child {
  border-right: none;
  border-bottom: none;
}
#all-boats .col-md-6.col-sm-12:nth-last-child(2) {
  border-bottom: none;
}

.boat-item span.carousel-control-next-icon {
  transform: scale(0.7);
}

#login .next-button {
  margin: auto;
  display: block;
  cursor: pointer;
}

a.select-boat-button {
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding: 7px 0;
  border: 2px solid #eb2529;
  border-radius: 10px;
  font-weight: bold;
  letter-spacing: 0.8px;
  font-size: 15px;
  color: #eb2529 !important;
  transition: 0.3s all ease;
  cursor: pointer;
  margin-bottom: 20px;
}

a.select-boat-button:hover {
  background: #eb2529 !important;
  color: white !important;
}

#selected-boat-detail .light-text {
  font-size: 2.4rem;
  font-weight: 400;
  margin: 0;
  margin-top: 20px;
}

#selected-boat-detail .bold-text {
  font-size: 3rem;
  font-weight: bolder;
  letter-spacing: 4px;
  margin-bottom: 40px;
  margin-bottom: 15px;
}

.review-charter-button > button {
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-family: inherit;
  border: 3px solid #eb262f;
  padding: 4px 16px;
  height: 52px;
  border-radius: 8px;
  -moz-transform: skewX(-23deg);
  -webkit-transform: skewX(-23deg);
  -o-transform: skewX(-23deg);
  -ms-transform: skewX(-23deg);
  transform: skewX(-23deg);
  transition: all 0.2s ease;
  font-size: 1.1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.review-charter-button > button:hover {
  background-color: #eb262f;
  color: white;
}

.review-charter-button-text {
  -moz-transform: skewX(23deg);
  -webkit-transform: skewX(23deg);
  -o-transform: skewX(23deg);
  -ms-transform: skewX(23deg);
  transform: skewX(23deg);
}

.img {
  max-width: 100%;
}

.red-button {
  width: 100%;
  display: block;
  padding: 15px 0;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  background: #eb2529 !important;
  transition: 0.2s all ease-in-out;
  text-align: center;
  border: 2px solid #eb2529;
  cursor: pointer;
}

.red-button:hover {
  background: white !important;
  color: #eb2529 !important;
}

.red-button.active {
  background: white !important;
  color: #eb2529 !important;
}

#selected-boat-detail .red-text {
  font-size: 24px;
}

.select-location {
  margin-top: 50px;
}

.select-location p {
  font-size: 24px;
  font-weight: bold;
}

.selected-boat-image-gallery {
  margin-top: 25px;
}

.location-picker {
  height: auto;
  margin-bottom: 20px;
}

.mobile-slider img {
  max-height: 800px;
}

.slider-mobile-caption {
  position: absolute;
  z-index: 9;
  top: 3%;
  padding: 0 25px;
  text-align: center;
}

.slider-mobile-caption p {
  margin: 0;
}

.slider-mobile-caption img.carousel-app-store {
  max-width: 100%;
  width: 150px;
  text-align: center;
  display: block;
  margin: auto;
  display: none;
}

.slider-mobile-caption .red-text {
  font-size: 20px;
}

.slider-mobile-caption h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

img.next-button {
  cursor: pointer;
}

.selected-boat-images img {
  width: 100%;
  border-radius: 15px;
}

/* li.geosuggest__item {
  list-style: none;
  display: block;
  z-index: 999;
  padding: 7px;
  border-bottom: 1px solid;
}

ul.geosuggest__suggests {
  position: absolute;
  top: 15%;
  z-index: 999;
  padding: 0;
  background: white;
  border: 1px solid;
} */

input.geosuggest__input {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  padding-bottom: 5px;
  padding-left: 10px;
  outline: none;
}

ul.geosuggest__suggests {
  padding: 5px;
  transition: 1s all ease;
}

li.geosuggest__item {
  list-style: none;
}

li.geosuggest__item span b {
  color: #eb2529 !important;
}

#selected-boat-detail span.item-property {
  font-weight: bold;
  padding-right: 5px;
}

#selected-boat-detail p.item-info {
  font-size: 24px;
}

.review-charter-button {
  display: block;
  text-align: center;
  margin: 50px auto;
  cursor: pointer;
}

h1.choosed-boat {
  font-weight: bolder;
  margin-top: 20px;
}

.location-preview {
  height: 30vh;
  width: 100%;
  margin-bottom: 30px;
}

.location-preview > div > div {
  border-radius: 20px;
}

.summary .red-text {
  font-size: 16px;
}
.summary p {
  font-weight: bold;
  margin-top: 10px;
}

.day-price p {
  font-weight: 500;
  font-size: 25px;
}

.location-preview p {
  font-weight: bold;
  font-size: 24px;
}

.location-preview .red-text {
  padding-right: 5px;
}

.fee-item {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
}

#accept-pay hr {
  border-top: 1px solid rgba(0, 0, 0, 0.68);
}

#accept-pay {
  margin: 80px 0;
}

.total-fee {
  font-weight: bolder;
  font-size: 22px;
}

.accept-pay-button {
  max-width: 100%;
  width: 350px;
  margin: auto;
  display: block;
  margin-top: 40px;
  cursor: pointer;
}

.selected-boat-image-gallery img {
  border-radius: 15px;
}

.react-phone-number-input__icon {
  border: none !important;
}

span.guest-numbers {
  display: inline-block;
  width: 58px;
  padding: 6px;
}

.dropdown-menu.show {
  transform: unset !important;
  top: 63px !important;
  box-shadow: 0 10px 35px -10px;
}

button#hotspot-dropdown {
  background: transparent;
  color: black;
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0;
  font-size: 35px;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

button.dropdown-item {
  border-bottom: 0.5px solid #dbdbdb;
  padding: 7px 15px;
  font-weight: bold;
}

button.dropdown-item:last-child {
  border: none !important;
}

.step-number {
  color: #eb2529;
}

.back-button {
  margin-bottom: 40px;
}

.back-button a {
  border: 2px solid #eb262f;
  padding: 10px 20px;
  border-radius: 18px;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 40px;
  transition: 0.3s all ease;
}

.back-button a:hover {
  background: #eb262f;
  color: white !important;
  text-decoration: none;
}

#payment .prices-fees {
  margin-bottom: 50px;
}

.select-payment p {
  background: #e7e2e2;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 19px;
}

.payment-methods input {
  float: right;
}

.payment-methods div {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
}

.payment-methods label {
  width: 100%;
}

.card-info input {
  border: none !important;
  border-bottom: 1px solid grey !important;
  margin-bottom: 10px;
  width: 100%;
}

.card-info input:focus {
  outline: none;
  border-bottom: 1px solid #eb2529 !important;
}

.card-item {
  margin: 0;
  margin-bottom: 5px;
  padding: 5px 0;
  border-bottom: 1px solid grey !important;
}

.credit-card-fee {
  width: 100%;
}

.call-button {
  background: #eb2529;
  margin: auto;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 50px;
  display: block;
}

.boat-image-slider {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  margin-bottom: 50px;
  border-radius: 5px;
  width: 100%;
  height: 550px;
  transition: 0.2s all cubic-bezier(0.17, 0.67, 0.59, 0.66);
}

.btn-form {
  background: #eb2529;
  color: #fff;
}
